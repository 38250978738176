body {
	background:  #e4e4e4;
	/* background: linear-gradient(0.50turn, #f6f1ff, #7e83a5); */
	background-attachment: fixed; /*edit*/
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-column {
  text-align: center;
  padding: 10px;
  border: 1px solid #000;
}

.table thead .table-column {
  background-color: #333;
  color: white;
  font-weight: bold;
}

.table tbody .table-column button {
	background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

table tbody .table-column button.disabled {
	background-color: red;
  opacity: 0.6;
  cursor: not-allowed;
}

.table tbody .table-column button:hover {
  background-color: #0056b3;
}
.table tbody .table-column button.disabled:hover {
	background-color: rgb(142, 2, 2);
}
